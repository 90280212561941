<template>
	<div class="app-view">
		<van-nav-bar title="个人信息" left-text="返回" left-arrow @click-left="$routerGo(-1)" />
		<van-cell-group>
			<van-cell title="头像" />
			<van-cell title="用户名" :value="userInfo.username" />
			<van-cell title="昵称" is-link :value="userInfo.nickname || '无'" to="changeNickname" />
			<van-cell title="性别" is-link :value="sexArr[userInfo.sex] || '请选择'" @click="() => showPicker=true" />
			<van-cell title="订单尾号" is-link :value="userInfo.order_remark || '无'" to="changeOrderremark" />
		</van-cell-group>
        <van-popup v-model="showPicker" round position="bottom">
		    <van-picker title="修改性别" show-toolbar :columns="sexArr" @confirm="onConfirm" @cancel="onCancel" :default-index="userInfo.sex" />
        </van-popup>
	</div>
</template>
<script>
export default {
	data() {
		return {
            showPicker: false,
			sexArr: ['男', '女', '未知']
		}
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo
		}
	},
    methods:{
        onConfirm(value, index) {
            this.showPicker = false
            console.log(`当前值：${value}, 当前索引：${index}`);
            this.$ask.post('/updateUserInfo', {sex: index})
                .then(res => {
                    this.$store.dispatch('login',res.data)
                    this.$toast('修改成功')
                })
                .catch(this.$toastError)
        },
        onCancel(){
            this.showPicker = false
        }
    }
}
</script>
